<template>
    <div class="overlay d-flex justify-content-center align-items-center" v-if="this.displayIntro === true">
        <div class="card onboarding-layout">
            <div class="container text-center">
                <div class="step" :class="{ active: step === 1}">
                    <div class="container-texto mb-5">
                        <h1>Seja bem-vindo ao Mapa de Atores</h1>
                        <p class="marg-20">Esta ferramenta visa encontrar e criar conexões entre pessoas (atores) e organizações de forma simples e intuitiva, com o propósito de aprimorar de forma estratégica futuros projetos, angariando novas parcerias.</p>
                        <button class="btn btn-dark-blue px-4 py-2 rounded-pill" @click="tryGoTo(2)">Próximo <img src="../assets/left-arrow-white.svg" class="next-icon" alt=""></button>
                    </div>
                </div>
                <div class="step" :class="{ active: step === 2}">
                    <img src="../assets/step2.jpg" alt="">
                    <p class="marg-20">Navegue pelo mapa de atores tocando nos atores e explorando suas conexões.</p>
                    <button class="btn btn-dark-blue px-4 py-2 rounded-pill" @click="tryGoTo(3)">Próximo <img src="../assets/left-arrow-white.svg" class="next-icon" alt=""></button>
                </div>
                <!-- <div class="step" :class="{ active: step === 3}">
                    <img src="../assets/step3.jpg" alt="">
                    <p class="marg-20">Tem uma busca específica? Digite o nome do ator e filtre pelos níveis de conexão.</p>
                    <button class="btn btn-dark-blue px-4 py-2 rounded-pill" @click="tryGoTo(4)">Próximo <img src="../assets/left-arrow-white.svg" class="next-icon" alt=""></button>
                </div> -->
                <div class="step" :class="{ active: step === 3}">
                    <img src="../assets/step4.jpg" alt="">
                    <p class="marg-20">Para saber mais sobre o ator, navegue pela janela que aparece ao lado.</p>
                    <button class="btn btn-dark-blue px-4 py-2 rounded-pill" @click="tryGoTo(4)">Próximo <img src="../assets/left-arrow-white.svg" class="next-icon" alt=""></button>
                </div>
                <div class="step" :class="{ active: step === 4}">
                    <img src="../assets/step5.jpg" alt="">
                    <p class="marg-20">Quer cadastrar um novo ator? Acesse o menu lateral,  complete os campos de cadastro e envie para aprovação.</p>
                    <button class="btn btn-dark-blue px-4 py-2 rounded-pill" @click="tryGoTo(5)">Próximo <img src="../assets/left-arrow-white.svg" class="next-icon" alt=""></button>
                </div>
                <div class="step" :class="{ active: step === 5}">
                    <img src="../assets/step6.jpg" alt="">
                    <p class="marg-20">Você pode criar uma parceria, nomeá-la e adicionar os atores que vão participar. Todas as parcerias criadas terão prévia aprovação.</p>
                    <button class="btn btn-dark-blue px-4 py-2 rounded-pill" @click="tryGoTo(6)">Próximo <img src="../assets/left-arrow-white.svg" class="next-icon" alt=""></button>
                </div>
                <div class="step" :class="{ active: step === 6}">
                    <img src="../assets/step7.jpg" alt="">
                    <p class="marg-20">Há algum problema que precisa resolver? Você também pode criar um plano de ação. Os planos criados terão prévia aprovação.</p>
                    <button class="btn btn-dark-blue px-4 py-2 rounded-pill" @click="tryGoTo(7)">Próximo <img src="../assets/left-arrow-white.svg" class="next-icon" alt=""></button>
                </div>
                <div class="step" :class="{ active: step === 7}">
                    <img src="../assets/step8.jpg" alt="">
                    <p class="marg-20">Se deseja adicionar uma conexão, acesse o menu lateral, adicione os atores a serem adicionados e indique o nível de proximidade.</p>
                    <router-link  :class="{ 'active': $route.name === 'Mapa.Conexoes' }" :to="{ name: 'Mapa.Conexoes' }">
                        <button class="btn btn-dark-blue px-4 py-2 rounded-pill" @click="start(1)">Vamos começar</button>
                    </router-link>
                </div>
                <!-- <div class="step" :class="{ active: step === 6}">

                </div>
                <div class="step" :class="{ active: step === 7}">

                </div>
                <div class="step" :class="{ active: step === 8}">

                </div>-->
                <div class="row botoes d-flex justify-content-center">
                    <template v-if="this.step > 1">
                        <a>
                            <button class="btn-transparent" @click="tryGoToNegative()"><img src="../assets/left-arrow.svg" alt=""> Voltar</button>
                        </a>
                    </template>
                    <template v-if="this.step === 1">
                        <router-link class="nav-link onboarding-box text-left" :class="{ 'active': $route.name === this.$route.name }" :to="{ name: this.$route.name }">
                            <button class="btn-transparent" @click="start(0)"><img src="../assets/left-arrow.svg" alt=""> Voltar</button>
                        </router-link>
                    </template>
                    <div class="buttons-step">
                        <button class="btn-step" :class="{ active: step === 1}" type="button" @click="tryGoTo(1)"></button>
                        <button class="btn-step" :class="{ active: step === 2}" type="button" @click="tryGoTo(2)"></button>
                        <button class="btn-step" :class="{ active: step === 3}" type="button" @click="tryGoTo(3)"></button>
                        <button class="btn-step" :class="{ active: step === 4}" type="button" @click="tryGoTo(4)"></button>
                        <button class="btn-step" :class="{ active: step === 5}" type="button" @click="tryGoTo(5)"></button>
                        <button class="btn-step" :class="{ active: step === 6}" type="button" @click="tryGoTo(6)"></button>
                        <button class="btn-step" :class="{ active: step === 7}" type="button" @click="tryGoTo(7)"></button>
                        <!-- <button class="btn-step" :class="{ active: step === 8}" type="button" @click="tryGoTo(8)"></button> -->
                    </div>
                    <router-link v-if="step !== 7" class="nav-link onboarding-box text-right" :class="{ 'active': $route.name === this.$route.name }" :to="{ name: this.$route.name }">
                       <button class="btn-transparent" @click="start(1)">Pular introdução</button> <img src="../assets/right-arrow.svg" alt="">
                    </router-link>
                    <div v-if="step === 7" style="width:200px;"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  name: 'OnboardingLayout',
  data () {
    return {
      step: 1,
      startValue: null,
      displayIntro: true
    };
  },
  beforeCreate () {
    if (localStorage.startValue === '1') {
      this.displayIntro = false;
    }
  },
  methods: {
    tryGoTo (destinationStep) {
      this.step = destinationStep;
    },
    tryGoToNegative () {
      this.step -= 1;
      this.tryGoTo(this.step);
    },
    start (value) {
      localStorage.startValue = value;
      this.displayIntro = false;
    }
  },
  watch: {
    start (value) {
      localStorage.startValue = value;
      this.displayIntro = false;
    }
  },
  mounted () {
    if (localStorage.startValue === '1') {
      this.startValue = localStorage.startValue;
      this.displayIntro = false;
    }
  }
};
</script>
<style scoped>
    .overlay{
        width: 100vw;
        height: 100vh;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.301);
        z-index: 10;
    }
    .btn-step{
        width: 14px;
        height: 14px !important;
        border: 0px;
        background-color: #DDDDDD;
        border-radius: 14px;
        padding: 0;
        margin: 3px 6px;
        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -ms-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;
    }
    .btn-step.active{
        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -ms-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;
        background-color: #0085CB !important;
    }
    p.marg-20{
        margin-top: 20px;
    }
    .btn{
        margin-top: 20px;
    }
    .btn-dark-blue{
        background-color: #2C3A65;
        color: white;
    }
    .btn-dark-blue:hover{
        color: rgb(197, 197, 197);
    }
    .step{
        display:none;
    }
    .step.active{
        display: block;
    }
    .card.onboarding-layout {
        width: 800px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 72px 0px 30px 0px;
    }
    .botoes{
        width:100%;
        align-items: center;
        margin:0;
        margin-top: 30px;
        justify-content: space-around !important;
    }
    .bold{
        font-weight: bold;
    }
    img{
        max-width: 100%;
    }
    h1{
        color: #2C3A65;
        font-size: 23px;
        font-weight: bold;
    }
    a{
        width: fit-content !important;
    }
    p{
        font-weight: 400;
    }
    .btn-transparent{
        background: transparent;
        border: 0;
        font-weight: bold;
        color: #044976;
    }
    .buttons-step{
        max-width: 372px;
        justify-content: center;
        display: flex;
        padding: 0;
    }
    .onboarding-box{
        width: 200px !important;
    }
    .text-left{
        text-align: left;
    }
    .text-right{
        text-align: right;
    }
    .container-texto{
        padding: 0 110px;
    }
    .next-icon{
        transform: translate(2px, -1px);
    }
    @media only screen and (max-height: 686px) {
        .onboarding-layout {
            zoom: 0.9;
        }
    }
</style>
