<template>
  <div class="c-navigation-side-bar d-flex flex-column flex-shrink-0 p-4 bg-white">
    <div class="dropdown">
      <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle justify-content-between w-75 m-auto" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="../assets/logo-letra-branca-fundo-azul.png" alt="" width="32" height="32" class="rounded-circle me-2">
        <strong>Admin</strong>
      </a>
      <ul class="dropdown-menu text-small shadow border-0 menu" aria-labelledby="dropdownUser1">
        <li v-if="idUsuario !== 0">
          <router-link class="btn dropdown-item" :to="{ name: 'MeuPerfil.Visualizacao', params: { id:  idUsuario } }">Meu Perfil</router-link>
        </li>
        <li>
          <button class="btn dropdown-item" @click="sair()"><img src="../assets/icons/logout_black_24dp.svg" class="sair-img">Sair</button>
        </li>
        <!-- <li><button type="button" class="dropdown-item" @click="handleLogoutRequest">Logout</button></li> -->
      </ul>
    </div>

    <hr class="d-flex my-4 bg-white" />

    <nav class="nav nav-pills flex-column my-auto">
      <!-- <div class="nav-item">
        <router-link class="nav-link" :class="{ 'active': $route.name === 'Dashboard' }" :to="{ name: 'Dashboard' }">
        <img src="../assets/icons/dashboard.svg" alt="">
          Dashboard
        </router-link>
      </div> -->
      <div class="nav-item">
        <router-link class="nav-link mb-1 text-secondary bg-gray-100" :class="{ 'active': /^Mapa.*$/.test($route.name) }" :to="{ name: 'Mapa.Conexoes' }">
          <img src="../assets/icons/mapa.svg" alt="">
            Mapa
        </router-link>
      </div>

      <div class="nav-item submenu">
        <router-link class="nav-link mb-1 text-secondary bg-gray-100" :class="{ 'active': /^Atores.*$/.test($route.name) }" :to="{ name: 'Atores' }">
        <img src="../assets/icons/atores.svg" alt="">
        Atores
        </router-link>
      </div>
      <ul class="m-0 p-0 list-unstyled sublist" :class="{ 'active': /^Atores.*$/.test($route.name) }">
        <li>
          <router-link class="nav-link" :class="{ 'active': $route.name === 'Atores' }" :to="{ name: 'Atores' }">Lista de atores</router-link>
        </li>
        <li>
          <router-link class="nav-link text-secondary" :class="{ 'active': $route.name === 'Atores.Cadastro' }" :to="{ name: 'Atores.Cadastro'}">Cadastrar ator</router-link>
        </li>
      </ul>

      <div class="nav-item submenu">
        <router-link class="nav-link mb-1 text-secondary" :class="{ 'active': /^Parcerias.*$/.test($route.name) }" :to="{ name: 'Parcerias' }">
        <img src="../assets/icons/parceria.svg" alt="">
        Parceria
        </router-link>
      </div>
      <ul class="m-0 p-0 list-unstyled sublist" :class="{ 'active': /^Parcerias.*$/.test($route.name) }">
        <li>
          <router-link class="nav-link" :class="{ 'active': $route.name === 'Parcerias' }" :to="{ name: 'Parcerias'}">Parcerias</router-link>
        </li>
        <li>
          <router-link class="nav-link" :class="{ 'active': $route.name === 'Parcerias.Cadastro' }" :to="{ name: 'Parcerias.Cadastro'}">Associar parceria</router-link>
        </li>
      </ul>

      <div class="nav-item submenu" v-if="idUsuario !== 0">
        <router-link class="nav-link mb-1 text-secondary" :class="{ 'active': /^Livre.Visualizacao.*$/.test($route.name) }" :to="{ name: 'Livre.Visualizacao', params: { id: idUsuario }}">
        <img src="../assets/icons/conexoes.svg" alt="">
        Conexões
        </router-link>
      </div>
      <ul class="m-0 p-0 list-unstyled sublist" :class="{ 'active': /^Livre.Visualizacao.*$/.test($route.name) }">
        <li>
          <router-link class="nav-link" :class="{ 'active': $route.name === 'Livre.Visualizacao' }" :to="{ name: 'Livre.Visualizacao', params: { id: idUsuario }}">Minhas conexões</router-link>
        </li>
        <li>
          <router-link class="nav-link" :class="{ 'active': $route.name === 'Livre.Cadastro' }" :to="{ name: 'Livre.Cadastro', params: { id: idUsuario }}">Criar Conexão</router-link>
        </li>
      </ul>

      <div class="nav-item submenu">
        <router-link class="nav-link mb-1 text-secondary" :class="{ 'active': /^PlanosDeAcao.*$/.test($route.name) }" :to="{ name: 'PlanosDeAcao' }">
        <img src="../assets/icons/plano-de-acao.svg" alt="">
        Plano de Ação
        </router-link>
      </div>
      <ul class="m-0 p-0 list-unstyled sublist" :class="{ 'active': /^PlanosDeAcao.*$/.test($route.name) }">
        <li>
          <router-link class="nav-link" :class="{ 'active': $route.name === 'PlanosDeAcao' }" :to="{ name: 'PlanosDeAcao'}">Planos</router-link>
        </li>
        <li>
          <router-link class="nav-link" :class="{ 'active': $route.name === 'PlanosDeAcao.Cadastro' }" :to="{ name: 'PlanosDeAcao.Cadastro'}">Criar plano</router-link>
        </li>
      </ul>
    </nav>
    <div class="d-flex align-items-center mb-3 mb-md-0 link-dark justify-content-center">
      <img src="../assets/logo-unibanco-jovem-de-futuro.svg" class="logo-sidebar" alt="Logo do Insituto Unibanco">
    </div>
  </div>
</template>

<script>
import Api from '../Auth';
export default {
  name: 'NavigationSideBar',
  data () {
    return {
      id: (this.$route.params.id === undefined ? 1 : this.$route.params.id)
    };
  },
  methods: {
    handleLogoutRequest () {
      localStorage.removeItem('token');
      this.$router.push({ name: 'Login' });
    },
    handleProfile () {
      const id = +this.$route.params.id;
    },
    sair () {
      const api = new Api();
      api.signOut();
    }
  },
  computed: {
    idUsuario () {
      return this.$store.state.idUsuario;
    }
  }
};
</script>

<style scoped>
.c-navigation-side-bar{
  z-index: 1;
  -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
}
.logo-sidebar {
  max-width: 240px;
  width: 100%;
}
.nav-link,.btn {
  color: #717171;
  font-weight: 500;
}
.nav-link img {
    padding-right: 10px;
}
.nav-item.submenu .nav-link.active::after {
  transform: rotate(90deg);
}
.nav-item.submenu .nav-link::after {
  content: close-quote;
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  margin-left: .5em;
  background-image: url('../assets/arrow-right.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  vertical-align: middle;
  transition: transform .2s ease-in-out;
}
.nav-pills .nav-link.active {
  background-color: #EDEFF3;
}
.nav-pills ul .nav-link.active {
  background-color: #EDEFF3;
  color: #717171;
}
.sublist {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  transition-delay: .1s;
}
.sublist.active {
  height: auto;
  opacity: 1;
}
.sublist li a{
  font-weight: 400;
  font-size: 14px;
}
.sublist li{
  list-style: initial;
}
.color-white{
  color: white;
}
.menu.dropdown-menu{
  width: 220px;
  padding: 10px;
  transform: translate(-16px, 34px) !important;
  text-align: center;
}
.sair-img{
  position: relative;
  top: -2px;
  padding-right: 4px;
}
</style>
