<template>
  <div class="layout-view-container">
    <OnboardingLayout />
    <NavigationSideBar />

    <div class="right-view-wrapper pb-4 pt-4 overflow-auto" :class="this.$route.name === 'Mapa.Conexoes' ? 'no-overflow' : ''">
      <router-view :key="$route.name"></router-view>
    </div>
  </div>
</template>

<script>
import NavigationSideBar from '../components/NavigationSideBar.vue';
import OnboardingLayout from '../components/OnboardingLayout.vue';

export default {
  components: {
    NavigationSideBar,
    OnboardingLayout
  }
};
</script>

<style scoped>

.right-view-wrapper {
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  background-color: #F7F7FA;
}
.no-overflow{
  overflow: hidden !important;
}
.layout-view-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
}
</style>
